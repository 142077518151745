.box {
	$s: &;

	will-change: auto;
	background: #6B675E;
	user-select: none;

	&__dot {
		transition: all linear .2s;
		cursor: pointer;

		&:hover {
			fill: $color-yellow;
			//filter: drop-shadow(0 0 4px rgba($color-black, .5)); // works only in Firefox :(
		}

		&--active{
			fill: $color-yellow;
		}
	}

	&__line{
		stroke-width: 6;

		transition: all linear .5s;
		border-radius: 100%;
		//filter: drop-shadow(0 0 20px rgba($color-black, .5)); // works only in Firefox :(
	}

	&__tale {
		transition: all linear .5s;
	}

	&__number {
		font-size: 1.5rem;
		pointer-events: none;
	}

	&__mouseline {
		pointer-events: none;
		//filter: drop-shadow(0 0 5px rgba($color-black, .8)); // works only in Firefox :(
	}
}

